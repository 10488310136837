<template>
  <div>
    <login-st v-if="$route.path==='/login'" @name="my"></login-st>
<!--    <router-view v-else-if=""></router-view>-->
    <HomeSt v-else :name="username"></HomeSt>
  </div>
</template>

<script>
import HomeSt from "@/views/Home/HomeSt.vue";
import LoginSt from "@/views/Login/LoginSt.vue";
export default {
  name: 'App',
  components:{
    HomeSt,
    LoginSt
  },
  data(){
    return{
          username:''
    }
  },
  methods:{
    my(name){
      this.username=name
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
