<template>
  <div class="by">
    <div class="content" v-if="$route.name !== '404'">
      <img class="image" src="../../image/渝景随行.png" alt="网站图标">
      <router-link to="/home"><div class="n">首页</div></router-link>
      <router-link to="/user"><div class="n">用户管理</div></router-link>
      <router-link to="/merchant"><div class="n">商店管理</div></router-link>
      <router-link to="/fsws"><div class="n">用户管理</div></router-link>
      <router-link to="/friend"><div class="n">商品管理</div></router-link>
      <div class="user">管理员：{{name}}</div>
    </div>
    <div class="nav">
      <router-view/>
    </div>
  </div>
</template>

<script>


export default{
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data(){
    return{
    }
  },
  methods:{
    login(){
      if (this.name === '' && this.$router) {
        this.$router.push('/login');
      }
    }
  },
  mounted() {
    this.login()
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: #e1e1e2;
}

html {
  height: 100%;
}
body{
  width: 100%;
  height: 100%;
  background: url("../../image/2.jpg") 100% 100% no-repeat;
  background-size: cover;
}
.content{
  width: 100%;
  background: #19191f;
  height: 100px;
  opacity: 0.8;
}
.nav{
  height: calc(100% - 100px);
}
.n{
  width: 80px;
  float: left;
  color: #e1e1e2;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  padding:0 20px;
  user-select: none;
  text-align: center;
}
.n:hover{
  color: #3388FF;
  background: #cccccc;
}
.n:hover a{
  color: #3388FF;
}
.user{
  color: #f1b7c3;
  font-size: 36px;
  font-family: 隶书,sans-serif;
  position: absolute;
  right: 10%;
  top: 4%;
  user-select: none;
}

.image{
  float: left;
  width: 120px;
  height: 40px;
  user-select: none;
  margin-top: 30px;
}
</style>
