<script>
import axios from "axios";
export default {
  data(){
      return{
          user:{
            username:'',
            password:''
          }
      }
  },
  methods:{
    login(){
      if(this.user.username===''){
        this.$message({
          message: '用户名不能为空',
          type: 'warning'
        });
        this.$refs.name.focus()
        return
      }
      if(this.user.password===''||this.user.password.includes(' ')){
        this.$message({
          message: '密码不能为空或密码中含有空格',
          type: 'warning'
        });
        this.user.password=''
        this.$refs.password.focus()
        return
      }
      axios.post("http://47.120.18.182:7000/User/login?name="+this.user.username+"&password="+this.user.password)
          .then(res=>{
            if(res.data.code){
              this.$message({
                type: 'success',
                message: `登录成功`
              });
              this.$emit('name',this.user.username)
              this.$router.push('/home')
            }else{
              this.$message.error('登录失败');
              this.user.username=''
              this.user.password=''
            }
          })
          .catch(error=>{
            this.$message.error('登录失败');
            this.user.username=''
            this.user.password=''
            console.error('Error fetching data:', error);
          })
    }
  }
}
</script>

<template>
<div class="by">
  <div class="ring">
    <i style="--clr:#00ff0a;"></i>
    <i style="--clr:#ff0057;"></i>
    <i style="--clr:#fffd44;"></i>
    <div class="login">
      <h2>Login</h2>
      <div class="inputBx">
        <input type="text" placeholder="Username" v-model="user.username" ref="name">
      </div>
      <div class="inputBx">
        <input type="password" placeholder="Password" v-model="user.password" ref="password">
      </div>
      <div class="inputBx">
        <input type="submit" value="Sign in" @click="login()"/>
      </div>
      <div class="links">
        <a href="#">Forget Password</a>
        <a href="#">Signup</a>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
.by {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #111;
  width: 100%;
  overflow: hidden;
  user-select: none;
}
.ring {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
}
.ring i:nth-child(1) {
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: animate 6s linear infinite;
}
.ring i:nth-child(2) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate 4s linear infinite;
}
.ring i:nth-child(3) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate2 10s linear infinite;
}
.ring:hover i {
  border: 6px solid var(--clr);
  filter: drop-shadow(0 0 20px var(--clr));
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.login {
  position: absolute;
  width: 300px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  left: 100px;
}
.login h2 {
  font-size: 2em;
  color: #fff;
}
.login .inputBx {
  position: relative;
  width: 100%;
}
.login .inputBx input {
  position: relative;
  width: 100%;
  padding: 12px 20px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 40px;
  font-size: 1.2em;
  color: #fff;
  box-shadow: none;
  outline: none;
}
.login .inputBx input[type="submit"] {
  width: 100%;
  background: #0078ff;
  background: linear-gradient(45deg, #ff357a, #fff172);
  border: none;
  cursor: pointer;
}
.login .inputBx input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.login .links {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.login .links a {
  color: #fff;
  text-decoration: none;
}
</style>
