<script>
import axios from 'axios';

export default {
  data() {
    return {
      keyWord: '',
      filarr: [],
      arr: [],
      user:[],
      showCard: false,
      newUser:{
        id:'',
        name:'',
        number: '',
        image: '',
        password: '',
        address:''
      },
      formId: 'frm',
      newClass: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get('http://47.120.18.182:7000/User/getAll')
          .then(res => {
            this.arr = res.data.data;
            this.filarr = this.arr; // 初始化filarr为arr的完整数据
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    },
    handleFilter(val) {
      this.filarr = this.arr.filter(item => item.name.includes(val));
    },
    del(id){
      axios.post('http://47.120.18.182:7000/User/delete?id='+id)
          .then(res=>{
            if(res.data.code){
              this.$message({
                type: 'success',
                message: `删除成功`
              });
              this.fetchData();
            }
          })
          .catch(error=>{
            console.error('Error fetching data:', error);
            this.$message.error('删除失败')
          })
    },
    open(val) {
      this.newClass='mask'
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        this.del(val)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    add(){
      this.showCard=true
      this.newClass="mask"
    },
    addUser(){
      if(this.newUser.name===''){
        this.$message({
          message: '用户名不能为空',
          type: 'warning'
        });
        this.$refs.name.focus()
        return
    }
      if(this.newUser.number===''){
        this.$message({
          message: '电话号码不能为空',
          type: 'warning'
        });
        this.$refs.nmber.focus()
        return
      }
      if(this.newUser.password===''||this.newUser.password.includes(' ')){
        this.$message({
          message: '密码不能为空或密码中含有空格',
          type: 'warning'
        });
        this.newUser.password=''
        this.$refs.password.focus()
        return
      }
      if(this.newUser.id===''){
        axios.post('http://47.120.18.182:7000/User/add?name='+this.newUser.name+'&number='+this.newUser.number+'&image='+this.newUser.image+'&password='+this.newUser.password)
            .then(res=>{
              if(res.data.code){
                this.$message({
                  type: 'success',
                  message: `添加成功`
                });
                this.fetchData();
                this.closeCard();
              }
            })
            .catch(error=>{
              console.error('Error fetching data:', error);

            })
      }else{
        axios.post('http://47.120.18.182:7000/User/update?id='+this.newUser.id+'&name='+this.newUser.name+'&number='+this.newUser.number+'&image='+this.newUser.image+'&password='+this.newUser.password)
            .then(res=>{
              if(res.data.code){
                this.$message({
                  type: 'success',
                  message: `修改成功`
                });
                this.fetchData();
                this.closeCard();
              }
            })
            .catch(error=>{
              console.error('Error fetching data:', error);
              this.$message.error('修改失败');
            })
      }

    },
    closeCard(){
      this.showCard=false
      this.newUser.name=''
      this.newUser.image=''
      this.newUser.number=''
      this.newUser.password=''
      this.newClass=''
    },
    edit(val){
      this.newClass="mask"
        axios.post('http://47.120.18.182:7000/User/getById?id='+val)
            .then(res=>{
              this.newUser=res.data.data
              this.showCard=true
              this.newUser.id=val
            })
            .catch(error=>{
              console.error('Error fetching data:', error);
              this.$message.error('查询失败');
            })
    }
  },

  watch: {
    keyWord: {
      deep: true,
      immediate: true,
      handler: 'handleFilter' // 使用methods中的handleFilter方法
    }
  }
};
</script>

<template>
  <div class="box">
    <div :class="newClass">
      <div class="text">用户数据管理</div>
      <input type="text" class="seek" placeholder="搜索姓名..." v-model="keyWord">
      <button class="add" @click="add()">新增</button>
      <div>
        <table class="tab">
          <thead>
          <tr>
            <th>ID</th>
            <th>姓名</th>
            <th>头像</th>
            <th>电话</th>
            <th>密码</th>
            <th>地址</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="filarr.length===0">
            <td colspan="7" class="null">没有找到匹配的数据</td>
          </tr>
          <tr v-for="(p,index) in filarr" :key="p.id">
            <td>{{index+1}}</td>
            <td>{{p.name}}</td>
            <td>{{p.image || '未知'}}</td>
            <td>{{p.number || '未知'}}</td>
            <td>{{p.password}}</td>
            <td>{{p.address || '未知'}}</td>
            <td class="btn_td">
              <button value="修改" class="btn" id="edit" @click="edit(p.id)">编辑</button>
              <button value="删除" class="btn" id="del" @click="open(p.id)">删除</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

<!--   新增用户卡片 -->
    <div class="card" v-show="showCard">
      <form @submit.prevent="addUser" :id="formId">
        <div class="card_title">
          <div class="tl">用户信息</div>
          <div class="cl" @click="closeCard()">X</div>
        </div>
        <table class="userAdd">
          <tr>
            <td>用户名：</td>
            <td>
              <input name="name" ref="name" type="text" v-model="newUser.name" placeholder="请输入用户名..."/>
            </td>
          </tr>
          <tr>
            <td>电话：</td>
            <td>
              <input name="number" ref="nmber" type="text" v-model="newUser.number" placeholder="请输入电话号码..."/>
            </td>
          </tr>
          <tr>
            <td>头像：</td>
            <td>
              <input name="image" ref="image" type="text" v-model="newUser.image" placeholder="请选择头像"/>
            </td>
          </tr>
          <tr>
            <td>密码：</td>
            <td>
              <input name="password" ref="password" type="password" v-model="newUser.password" placeholder="密码"/>
            </td>
          </tr>
          <tr>
            <td>地址：</td>
            <td>
              <input name="text" type="text" v-model="newUser.address" placeholder="地址"/>
            </td>
          </tr>
          <tr>
            <td>
              <button type="submit" class="putIn">提交</button>
            </td>
            <td>
              <button type="reset" @click="closeCard()" class="cancel">取消</button>
            </td>
          </tr>
        </table>
      </form>

    </div>
  </div>
</template>

<style scoped>
.box{
  height: 100%;
}
.add{
  font-size: 25px;
  border: 1px solid #38F;
  border-radius: 5px;
  user-select: none;
  width: 80px;
  height: 20%;
  margin-bottom: 20px;
  position: relative;
  left: calc(50% - 40px);
}
.add:hover{
  background: #c6e2ff;
  color: #38F;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.seek{
  display: block;
  width: 240px;
  font-size: 20px;
  margin-bottom: 40px;
  position: relative;
  left: calc(50% - 120px);
  top: 20px;
}
.text{
  font-size: 35px;
  letter-spacing: 6px;
  text-align: center;
  color: aquamarine;
  font-family: 隶书,serif;
  user-select: none;
}
/* 滚动条宽度 */

::-webkit-scrollbar {
  width: 1px;
  background-color: transparent;
}

/* 滚动条颜色 */
::-webkit-scrollbar-thumb {
  background-color: #27314d;
}

/* 表格样式*/
.tab {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.tab caption{
  font-weight: bold;
  font-size: 24px;
  line-height: 50px;
}

.tab th, .tab td {
  height: 60px;
  text-align: center;
  border: 1px solid gray;
}

.tab thead {
  color: white;
  background-color: #3388FF;
  user-select: none;
}

.tab tbody {
  display: block;
  width: 100%; /*这里的8px是滚动条的宽度*/
  height: 400px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.tab thead tr, .tab tbody tr, .tab tfoot tr {
  box-sizing: border-box;
  table-layout: fixed;
  display: table;
  width: 100%;
}
/* 奇偶行样式不同*/
.tab tbody tr:nth-of-type(odd) {
  background: #EEE;
}

.tab tbody tr:nth-of-type(even) {
  background: #FFF;
}

th , tr{
  border-bottom: 1px solid #808080;
}

.btn{
  font-size: 50%;
  width: 30%;
  height: 50%;
  margin: 5%;
  border-radius: 10px;
  border: 1px solid #cccccc;
}

#del{
  color: #cccccc;
  background: #e31e1e;
}
#del:hover{
  background: #f89898;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
#edit:hover{
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #c6e2ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.null{
  font-size: 25px;
  color: red;
  letter-spacing: 10px;
  font-family: "华文宋体",sans-serif;
  user-select: none;
}

/* 新增卡片的样式*/
.card{
  background: #cccccc;
  width: 500px;

  border: 2px solid #c6e2ff;
  border-radius: 20px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  position: absolute;
  top:calc(50% - 250px);
  left: calc(50% - 250px);
}

.card_title .tl{
  font-size: 36px;
  text-align: center;
  font-family: 隶书,sans-serif;
  user-select: none;
}
.card_title .cl{
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.card_title .cl:hover{
  color: #c6e2ff;
  background: #cb1319;
}
.userAdd{
  padding: 35px;
  font-family: 隶书,sans-serif;
  margin: auto;
}

.userAdd tr{
  /* 表格行高*/
  height: 80px;
}
.userAdd td{
  /*表格字体大小*/
  font-size: 32px;
}
.userAdd input{
  /*表格输入框文字大小*/
  font-size: 24px;
}
.userAdd button{
  font-size: 24px;
  width: 80px;
  height: 40px;

  display: block; /* 确保按钮占用整个单元格的宽度 */
  margin: 0 auto; /* 上下外边距为0，左右外边距自动，使按钮水平居中 */
  text-align: center; /* 文本居中，虽然对于按钮本身来说不是必需的，但对于单元格内的其他文本内容可能是有用的 */
}
.userAdd .cancel{
  color: #cccccc;
  background: #e31e1e;
  border: 2px solid #c6e2ff;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
}
.userAdd .cancel:hover{
  color: #f1b7c3;
  background: #f89898;
}
.userAdd .putIn{
  color: #cccccc;
  background: #009688;
  border: 2px solid #c6e2ff;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
}
.userAdd .putIn:hover{
  color: #f1b7c3;
  background: #00bfff;
}
/*
  添加遮罩层
*/
.mask{

}
</style>
