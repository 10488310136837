import VueRouter from 'vue-router'
import Vue from "vue";
import MerchantTab from "@/views/Data/MerchantTab.vue";
import Friend from "@/views/Data/FriendE.vue";
import UserTab from "@/views/Data/UserTab.vue";
import HomePage from "@/views/Home/HomePage.vue";
import LoginSt from "@/views/Login/LoginSt.vue";
import NotFound from "@/views/404/NotFound.vue";
Vue.use(VueRouter)
const router = new VueRouter({
  routes:[
    {path:'/',redirect:'/login'},
    {path:'/home',component:HomePage} ,
    {path:'/merchant',component: MerchantTab},
    {path:'/friend',component: Friend},
    {path: '/user' , component: UserTab},
    {path:'/login',component:LoginSt},
    {path:'*' ,component:NotFound,name:'404'}
  ]
})
export default router;
